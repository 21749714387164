import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"

class FormPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      status: null,
      msg: "",
      success: false,
    }
  }

  handleServerResponse = (ok, msg, form) => {
    this.setState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  send = (form) => {
    const url = `${process.env.GATSBY_SERVER_ADDCLUB}`
    axios.post(url, new FormData(form)).then((data) => {
      console.log(data)
      let msg = ""
      let success = false
      if (data.data.error) {
        msg = "Fields errors"
      }
      if (data.data.exists) {
        msg = "This club already exists"
      }
      if (data.data.success) {
        msg = "Success sent, please wait for approved"
        success = true
      }
      this.setState({ loading: false, msg: msg, success: success }, () => {})
    })
  }

  handleOnSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    this.setState({ loading: true }, this.send(form))
  }

  render() {
    const { success, msg } = this.state
    const button = { url: "/clubs/add/", text: "Add Club" }
    return (
      <>
        <Layout parent={"clubs"} siteTitle={"Add Club"}>
          <section className="breadcrumb-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb nav-breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/clubs">Clubs</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </section>
          <section className="sport-safety-area">
            <div className="tab-content-area">
              <div className="container simple-page">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <h1>Club information</h1>
                        <h3>Add or Change club information</h3>
                        <p>
                          Please feel free to submit your club information to
                          Swim Ontario. You will need to complete all the
                          required fields in the form below. All submissions
                          will be approved by Swim Ontario prior to appearing in
                          the club profiles. If your club is new to our list, we
                          will review your entry and then add it to the profiles
                          list. If your club already exists on our database, we
                          will update your new information. Fields containing *
                          are mandatory.
                        </p>
                        <br />
                        {!success && (
                          <form onSubmit={this.handleOnSubmit}>
                            {" "}
                            <h3>Club Information</h3>
                            {msg && <p className="text-danger">{msg}</p>}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="clubname">Club Name:*</label>
                              <input
                                required="true"
                                name="name"
                                id="clubname"
                                className="form-control"
                                placeholder="Enter name"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="abbreviation">
                                Abbreviation:*
                              </label>
                              <input
                                required="true"
                                name="abbreviation"
                                id="abbreviation"
                                className="form-control"
                                placeholder="Enter abbreviation"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="region">Region:*</label>
                              <select
                                name="region"
                                data-kpxc-id="kpxcpw342845663"
                                required="true"
                                id="region"
                                className="form-control"
                              >
                                <option value=""></option>
                                <option value="Central">Central</option>
                                <option value="Eastern">Eastern</option>
                                <option value="Huronia">Huronia</option>
                                <option value="Northwest">Northwest</option>
                                <option value="Northeast">Northeast</option>
                                <option value="Western">Western</option>
                                <option value="Masters">Masters</option>
                              </select>
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="website">Website:*</label>
                              <input
                                required="true"
                                name="website"
                                id="website"
                                className="form-control"
                                placeholder="Enter WebSite"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="description">Description:*</label>
                              <input
                                required="true"
                                name="description"
                                id="description"
                                className="form-control"
                                placeholder="Enter WebSite"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="address">Address:*</label>
                              <input
                                required="true"
                                name="address"
                                id="address"
                                className="form-control"
                                placeholder="Enter address"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="city">City:*</label>
                              <input
                                required="true"
                                name="city"
                                id="city"
                                className="form-control"
                                placeholder="Enter city"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="province">Province:*</label>
                              <input
                                required="true"
                                name="province"
                                id="province"
                                className="form-control"
                                placeholder="Enter province"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="postalcode">Postal Code:*</label>
                              <input
                                required="true"
                                name="postalcode"
                                id="postalcode"
                                className="form-control"
                                placeholder="Enter postalcode"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="phone">Phone:*</label>
                              <input
                                required="true"
                                name="phone"
                                id="phone"
                                className="form-control"
                                placeholder="Enter phone"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="fax">Fax:*</label>{" "}
                              <input
                                required="true"
                                name="fax"
                                id="fax"
                                className="form-control"
                                placeholder="Enter fax"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="email">Email:*</label>{" "}
                              <input
                                type="email"
                                required="true"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Enter email"
                              />
                            </div>{" "}
                            <h3>President Information</h3>
                            <div className="form-group">
                              {" "}
                              <label htmlFor="firstname">
                                First Name:
                              </label>{" "}
                              <input
                                type="firstname"
                                name="presidentfirstname"
                                id="presidentfirstname"
                                className="form-control"
                                placeholder="Enter firstname"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="lastname">Last Name:</label>{" "}
                              <input
                                type="lastname"
                                name="presidentlastname"
                                id="presidentlastname"
                                className="form-control"
                                placeholder="Enter last name"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="email">Email:</label>{" "}
                              <input
                                type="email"
                                name="presidentemail"
                                id="presidentemail"
                                className="form-control"
                                placeholder="Enter email"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="phone">Phone:</label>
                              <input
                                name="presidentphone"
                                id="phone"
                                className="form-control"
                                placeholder="Enter phone"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="fax">Fax:</label>{" "}
                              <input
                                name="presidentfax"
                                id="presidentfax"
                                className="form-control"
                                placeholder="Enter fax"
                              />
                            </div>{" "}
                            <h3>Head-Coach Information</h3>
                            <div className="form-group">
                              {" "}
                              <label htmlFor="firstname">
                                First Name:
                              </label>{" "}
                              <input
                                type="firstname"
                                name="headcoachfirstname"
                                id="headcoachfirstname"
                                className="form-control"
                                placeholder="Enter firstname"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="lastname">Last Name:</label>{" "}
                              <input
                                type="lastname"
                                name="headcoachlastname"
                                id="headcoachlastname"
                                className="form-control"
                                placeholder="Enter last name"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="email">Email:</label>{" "}
                              <input
                                type="email"
                                name="headcoachemail"
                                id="headcoachemail"
                                className="form-control"
                                placeholder="Enter email"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="phone">Phone:</label>
                              <input
                                name="headcoachphone"
                                id="phone"
                                className="form-control"
                                placeholder="Enter phone"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="fax">Fax:</label>{" "}
                              <input
                                name="headcoachfax"
                                id="headcoachfax"
                                className="form-control"
                                placeholder="Enter fax"
                              />
                            </div>{" "}
                            <h3>Club Official Information</h3>
                            <div className="form-group">
                              {" "}
                              <label htmlFor="firstname">
                                First Name:
                              </label>{" "}
                              <input
                                type="firstname"
                                name="clubfirstname"
                                id="clubfirstname"
                                className="form-control"
                                placeholder="Enter firstname"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="lastname">Last Name:</label>{" "}
                              <input
                                type="lastname"
                                name="clublastname"
                                id="clublastname"
                                className="form-control"
                                placeholder="Enter last name"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="email">Email:</label>{" "}
                              <input
                                type="email"
                                name="clubemail"
                                id="clubemail"
                                className="form-control"
                                placeholder="Enter email"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="phone">Phone:</label>
                              <input
                                name="clubphone"
                                id="phone"
                                className="form-control"
                                placeholder="Enter phone"
                              />
                            </div>{" "}
                            <div className="form-group">
                              {" "}
                              <label htmlFor="fax">Fax:</label>{" "}
                              <input
                                name="clubfax"
                                id="clubfax"
                                className="form-control"
                                placeholder="Enter fax"
                              />
                            </div>{" "}
                            <h3> Extra Information </h3>
                            <small>
                              {" "}
                              (Short description i.e. What's changed at your
                              club, or a special message)
                            </small>
                            <div className="form-group">
                              {" "}
                              <textarea
                                name="extra"
                                id="extra"
                                className="form-control"
                                placeholder="Enter description extra"
                              />
                            </div>{" "}
                            <button
                              className="button-load-more"
                              style={{ float: "right" }}
                              type="submit"
                            >
                              Save
                            </button>
                          </form>
                        )}
                        {success && (
                          <div>
                            <Link to="/">Home</Link>{" "}
                            <p className="text-success">{msg}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

FormPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query {
    wagtail {
      page(slug: "clubs-list") {
        ... on SimplePage {
          id
          body
          descriptionSimple
          button {
            name
            text
            url
          }
          ancestors {
            ... on ClubsPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                }
                ... on FindClub {
                  live
                }
              }
              button {
                text
                name
                url
              }
            }
          }

          title
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`

export default FormPage
